import { template as template_a89f7d159dd24d6eb175758250624c3b } from "@ember/template-compiler";
const FKControlMenuContainer = template_a89f7d159dd24d6eb175758250624c3b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
