import { template as template_850b55824fab4c8cb8fb4b2b402a9ff7 } from "@ember/template-compiler";
const WelcomeHeader = template_850b55824fab4c8cb8fb4b2b402a9ff7(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
