import { template as template_4f2ddee1a7ea4650952da8253facd83d } from "@ember/template-compiler";
const FKText = template_4f2ddee1a7ea4650952da8253facd83d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
